@import './style/scss-variables.scss';
@import './style/css-variables.scss';

@font-face {
  font-family: 'Montserrat';
  src: url(./fonts/Montserrat-Medium.ttf) format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Montserrat';
  src: url(./fonts/Montserrat-SemiBold.ttf) format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Montserrat';
  src: url(./fonts/Montserrat-Bold.ttf) format('truetype');
  font-weight: 700;
}

html {
  font-size: 62.5%;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', Helvetica, Arial, sans-serif;
  // background-color: rgb(230, 230, 230);
  background-color: var(--color-white);
  font-weight: 400;
  color: var(--color-black);
}

h1 {
  margin-top: 0;
  font-size: 3.5rem;
  font-weight: 600;
  margin-bottom: 50px;
  @include sm {
    font-size: 4rem;
  }
}

h2 {
  font-weight: 600;
  font-size: 2.2rem;
}

h3 {
  font-weight: 600;
  font-size: 1.6rem;
  margin-top: 0;
  margin-bottom: 5px;
}

p {
  line-height: 1.5;
  font-weight: 400;
  font-size: 1.6rem;
}

.text-container {
  margin: auto;
  max-width: 512px;
}

a {
  font-weight: 400;
  font-size: 1.6rem;
  color: var(--color-blue);
  &:active {
    color: var(--color-blue-active);
  }
  &:hover {
    color: var(--color-blue-active);
  }
}

ul,
li {
  padding-left: 0;
  list-style: none;
}

.lazy-spinner {
  position: absolute;
  top: 0;
  left: 0;
  @include lg {
    left: 250px;
  }
  bottom: 0;
  right: 0;
  display: flex;
}
