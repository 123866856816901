.desktop-navigation {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 250px;
  background-color: var(--color-black);
  box-shadow: 0px 16px 40px var(--color-disabled);

  &__logo--container {
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__link-container {
    margin-left: 20px;
    a {
      color: var(--color-gray);
      text-decoration: none;
    }
  }
}
