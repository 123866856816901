.edit-link {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 30px;
  background-color: transparent;
  color: var(--color-gray);
  border: none;
  outline: none;
  transition: background-color 500ms;
  cursor: pointer;

  &--active {
    color: var(--color-orange) !important;
    &:hover {
      color: var(--color-orange-active) !important;
    }
  }
  &--disabled {
    color: var(--color-disabled) !important;
    cursor: not-allowed;
    &:hover {
      color: var(--color-disabled) !important;
    }
  }

  div {
    display: flex;
    justify-content: center;
  }
  p {
    font-family: 'Montserrat', Helvetica, Arial, sans-serif;
    margin: 0;
    font-weight: 600;
    margin-left: 15px;
  }

  &__arrow {
    transition: 500ms;
    opacity: 0;

    &--active {
      opacity: 1;
    }

    &--hidden {
      width: 0;
    }
  }
  &__check {
    opacity: 0;
    &--active {
      opacity: 1;
    }

    &--hidden {
      width: 0;
    }
  }
}
