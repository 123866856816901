.nav-back-link {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 30px;

  transition: color 250ms;
  background-color: transparent;
  border: none;
  color: var(--color-gray);
  cursor: pointer;
  position: absolute;
  bottom: 0;
  outline: none;

  div {
    display: flex;
    justify-content: center;
  }
  p {
    margin: 0;
    font-weight: 600;
    margin-left: 15px;
  }

  &.active {
    color: var(--color-orange) !important;
  }

  &:hover {
    color: var(--color-orange);
  }
}
