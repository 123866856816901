@import '../../../style/scss-variables.scss';

.mobile-admin-navigation {
  position: fixed;
  bottom: 0;
  height: 64px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  box-shadow: 0px 16px 40px var(--color-disabled);
  z-index: 100;

  &__link-container {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 92%;

    @include rwd(300) {
      width: 80%;
    }
    @include rwd(376) {
      width: 75%;
    }
    @include rwd(450) {
      width: 70%;
    }
  }

  a {
    text-decoration: none;
  }

  &__link {
    color: var(--color-gray);
    position: relative;

    &--notification {
      &::after {
        position: absolute;
        content: attr(data-before-content);
        background-color: var(--color-orange);
        border-radius: 16px;
        font-size: 0.8rem;
        font-weight: 600;
        text-align: center;
        padding-top: 3px;
        width: 16px;
        height: 13px;
        top: -5px;
        right: -5px;
        color: var(--color-white);
      }
    }

    transition: color 200ms;
    &.active {
      color: var(--color-orange);
    }

    &:hover {
      color: var(--color-orange);
    }

    div {
      text-align: center;
      font-size: 1.1rem;
    }
  }
}
