@import '../../../style/scss-variables.scss';

.toast-prompt {
  position: fixed;
  top: 25px;
  left: 24px;
  right: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 300;

  @include sm {
    left: 40px;
    right: 40px;
  }
  @include lg {
    left: 290px;
  }

  &__prompt {
    background-color: rgba(88, 84, 79, 0.98);
    box-shadow: 0px 16px 40px rgba(88, 84, 79, 0.5);
    display: flex;
    align-items: center;
    padding-left: 25px;
    border-radius: 25px;
    color: var(--color-white);

    width: 100%;
    max-width: 512px;

    p {
      padding-top: 1px;
      margin-left: 15px;
    }
  }
}
