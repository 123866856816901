.category-logo {
  h3 {
    color: var(--color-gray);
    font-weight: 600;
    font-size: 1.6rem;
    text-align: center;
    margin-bottom: 0;
  }

  span {
    font-weight: 400;
  }
}
