.progress-bar {
  background-color: var(--color-gray);
  width: 100%;
  height: 5px;
  position: fixed;
  top: 0;
  z-index: 100;

  div {
    transition: width 1s;
    z-index: 101;
    width: 0%;
    height: 5px;
    background-color: var(--color-orange);
  }
}
