@import '../../../style/scss-variables';

.navigation {
  &__desktop {
    display: none;
    @include lg {
      display: block;
    }
  }
  &__mobile {
    display: block;
    @include lg {
      display: none;
    }
  }
}
