:root {
  --color-white: #f8f8f8;
  --color-black: #423f3c;
  --color-gray: #b0a79d;
  --color-disabled: rgba(176, 167, 157, 0.5);
  --color-blue: #0099f2;
  --color-blue-active: #0089d9;
  --color-red: #f24a00;
  --color-red-active: #d94100;
  --color-green: #81c300;
  --color-green-active: #70a800;
  --color-yellow: rgb(240, 192, 0);
  --color-yellow-active: #d9ad00;
  --color-orange: rgb(242, 148, 0);
  --color-orange-active: rgb(219, 135, 0);
  --color-orange-hover: rgba(242, 148, 0, 0.5);
}
